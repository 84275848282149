<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

  export default {
  name: 'App',
}
</script>

<style lang="scss">
  @import "assets/reset.css";
  /*@import '~swiper/swiper-bundle.css';*/




  input,
  textarea,
  button,
  select,
  a,
  span{
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
  }

  *,
  :before,
  :after {
    box-sizing: border-box;
  }

  .picker{
    background-color: transparent !important;
  }

  .picker-opened>.picker-dialog {
    bottom: 0 !important;
    width: 500px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  button:focus{
    outline: none;
  }

  html, body{
    width: 100%;
    height: 100%;
  }

  @font-face {
    font-family: 'English 157 BT';
    font-style: normal;
    font-weight: 400;
    src: local('English 157 BT'), local('English157BT-Regular'),
    url(assets/fonts/18872.ttf) format('truetype');
  }

  .v--modal-overlay .v--modal-box{
    overflow: visible !important;
  }


  #app {
    width: 100%;
    height: 100%;}


  .v--modal{
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .v--modal-overlay{
    background: rgba(0, 0, 0, 0.75) !important;

  }

  .owl-carousel{
    @include for-phone-only{
      height: 239px;
      width: 375px;
    }

    @include for-phone-landscape-up{
      height: 440px;
      width: 575px;
    }

    @include for-tablet-up{
      height: 587px;
      width: 768px;
    }

    @include for-desktop-up{
      height: 587px;
      width: 768px;
    }

    @include for-big-desktop-up{
      height: 587px;
      width: 768px;
    }
  }

  .owl-carousel-schedule{
    @include for-phone-only{
      height: 239px;
    }

    @include for-phone-landscape-up{
      height: 440px;
    }

    @include for-tablet-up{
      height: 587px;
    }

    @include for-desktop-up{
      height: 587px;
    }

    @include for-big-desktop-up{
      height: 587px;
    }
  }

  .tns-outer{
    width: 100%;
  }

  .tns-item{
    height: 100%;

  }


  .owl-carousel{
    @include for-phone-only{
      height: 239px;
      width: 375px;
    }

    @include for-phone-landscape-up{
      height: 440px;
      width: 575px;
    }

    @include for-tablet-up{
      height: 587px;
      width: 768px;
    }

    @include for-desktop-up{
      height: 587px;
      width: 768px;
    }

    @include for-big-desktop-up{
      height: 587px;
      width: 768px;
    }
  }

  .owl-dot {
    width: 24px !important;
    height: 4px !important;
    border-radius: 2.5px !important;
    margin-right: 8px;
    background-color: #EBECEF !important;
    opacity: 1 !important;
    border: 0 !important;
    cursor: pointer;
  }

  .swiper-pagination-bullet:focus,.swiper-pagination-bullet:active {
    outline: 0;
  }



  .owl-dot.active{
    background-color: #F54E5E !important;
  }

  .slider{
    height: 106px !important;
    width: 2px !important;
  }

  .slider-track{
    width: 2px !important;
    background-color: #E0E0E1 !important;
  }

  .slider-selection{
    background-color: #F54E5E !important;
    background-image: none !important;
  }


  .tooltip{
    display: none;
  }

  .slider-handle{
    background-color: transparent !important;
    background: url("assets/img/time.svg") no-repeat !important;
    border-radius: 0 !important;
    margin-left: -5px;
    width: 13px !important;
    height: 17px !important;
  }


  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  input::-webkit-caps-lock-indicator {
    visibility: hidden;
  }

  input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }

  .swiper-slide{
    background-color: black;
    position: relative;
    @include for-phone-only{
      width: 375px !important;
      height: 239px !important;
    }

    @include for-phone-landscape-up{
      width: 575px !important;
      height: 440px !important;
    }

    @include for-tablet-up{
      width: 768px !important;
      height: 587px !important;
    }

    @include for-desktop-up{
      width: 768px !important;
      height: 587px !important;

    }

    @include for-big-desktop-up{
      width: 768px !important;
      height: 587px !important;

    }
  }


  button{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }


  .enjoyhint_skip_btn{
    transition: all .2s !important;
    background: #745489 !important;
    width: 100px !important;
    height: 36px !important;
    font: normal normal normal 17px/37px "Advent Pro",Helvetica,sans-serif !important;
    font-family: Roboto, "sans-serif" !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: white !important;
    letter-spacing: 0 !important;
    border: 1px solid rgba(63,24,88,0.25) !important;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.24) !important;


    @include for-mobile-devices{
      width: 36px !important;
      height: 36px !important;
      font-size: 160% !important;
    }


    &:hover{
      filter: brightness(94%);
    }
  }

  .enjoyhint_next_btn{
    transition: all .2s !important;
    background: #745489 !important;
    width: 100px !important;
    height: 36px !important;
    font: normal normal normal 17px/37px "Advent Pro",Helvetica,sans-serif !important;
    font-family: Roboto, "sans-serif" !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: white !important;
    letter-spacing: 0 !important;
    border: 1px solid rgba(63,24,88,0.25) !important;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.24) !important;

    @include for-mobile-devices{
      width: 36px !important;
      height: 36px !important;
      font-size: 160% !important;
    }


    &:hover{
      filter: brightness(94%);
    }
  }

  .enjoyhint_prev_btn{
    transition: all .2s !important;
    background: #745489 !important;
    width: 100px !important;
    height: 36px !important;
    font: normal normal normal 17px/37px "Advent Pro",Helvetica,sans-serif !important;
    font-family: Roboto, "sans-serif" !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: white !important;
    letter-spacing: 0 !important;
    border: 1px solid rgba(63,24,88,0.25) !important;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.24) !important;


    @include for-mobile-devices{
      width: 36px !important;
      height: 36px !important;
      font-size: 160% !important;
    }

    &:hover{
      filter: brightness(94%);
    }
  }

  .enjoyhint_close_btn{
    transition: all .2s !important;
    background: #745489 !important;
    width: 36px !important;
    height: 36px !important;
    font: normal normal normal 17px/37px "Advent Pro",Helvetica,sans-serif !important;
    font-family: Roboto, "sans-serif" !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: white !important;
    letter-spacing: 0 !important;
    border: 1px solid rgba(63,24,88,0.25) !important;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.24) !important;
    border-radius: 50% !important;


    @include for-mobile-devices{
      width: 36px !important;
      height: 36px !important;
      font-size: 160% !important;
    }
  }

  .enjoyhint_svg_wrapper{
    overflow: hidden;
  }

  svg{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  .enj_canvas{
    overflow: hidden;
  }

  .chatra--side-bottom{
    @include for-mobile-devices{
      bottom: 70px !important;
    }

  }

  .enjoy_hint_label{
    border-radius: 10px !important;
    font-size: 16px !important;
    padding: 14px 20px;
    text-align: left !important;
    line-height: 18px;
    font-family: Roboto, "sans-serif" !important;
    font-weight: 400 !important;
    color: #4a4a4a !important;
    background-color: rgba(255,255,255,0.9) !important;
    width: 368px !important;
    box-shadow: 0 1px 9px 0 rgba(0,0,0,0.32) !important;



    @include for-mobile-devices{
      font-size: 14px !important;
    }

    @include for-desktop-devices{
      font-size: 16px !important;
    }
  }

  html, body{
    background-color: #f8f8f8 !important;
    width: 100%;
    height: 100%;

  }


  .slick-slider .slick-track, .slick-slider .slick-list {
    transform: none !important;
  }


  div:focus{
    outline: 0;
  }


</style>
