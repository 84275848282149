import Vue from 'vue'
import Vuex from 'vuex'
import moment from "moment";
import router from "@/router";
import {AccountGateway} from "../gateway/AccountGateway";
import {ScheduleGateway} from "../gateway/ScheduleGateway";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        profile: null,
        profileGateway: new AccountGateway(),
        scheduleGateway: new ScheduleGateway(),
        teachers: [],
        token: null,
        schoolAccount : null,
        isLoginExists: false,
        lessons: [],
        timeNow: null,
        secondsProgressLesson : 0,
        payStatus : true,
        activeLesson : false,
        deviceToken: "",
        activeLessons : [],
        isPushEnable : false,
        isFullscreenCamera: false,
        isFullscreenSchedule : false

    },
    mutations: {
        SET_PUSH_ENABLED(state,isEnabled){
          state.isPushEnable = isEnabled
        },

        SET_PROFILE: (state, profile) => {
            state.profile = { ...profile }
        },

        SET_SCHOOL: (state, school) => {
            state.schoolAccount = {...school}
        },

        SET_LOGIN_EXISTS: (state,isExists) => {
            state.isLoginExists = isExists
        },

        // SET_LESSONS: (state,lessons) => {
        //     state.lessons = [...lessons]
        // },

        SET_LESSONS: (state ,{lessons,classId}) => {
            state.lessons[classId] = [...lessons]
            state.lessons = [...state.lessons]
        },

        SET_TIME_NOW: (state,time ) => {
            state.timeNow = time
        },

        SET_PAY_STATUS: (state,status) => {
            state.payStatus = status
        },

        SET_ACTIVE_LESSON: (state,activeLesson) => {
            state.activeLesson = activeLesson
        },

        SET_ACTIVE_LESSONS: (state,{activeLesson,classId}) => {
            state.activeLessons[classId] = activeLesson
            state.activeLessons = [...state.activeLessons]
        },


        SET_TEACHERS: (state, teachers) =>{
            state.teachers = [...teachers]
        },

        SET_DEVICE_TOKEN: (state,token) =>{

            state.deviceToken = token
        },

        SET_FULLSCREEN_CAMERA: (state, isFullscreen) => {
            state.isFullscreenCamera = isFullscreen
        },
        SET_FULLSCREEN_SCHEDULE:(state,isFullscreen) => {
            state.isFullscreenSchedule = isFullscreen
        }
    },
    getters:{
        IS_PUSH_ENABLED: state => {
          return state.isPushEnable
        },
        DEVICE_TOKEN: state => {
            return state.deviceToken
        },
        PROFILE: state => {
            return state.profile
        },
        SCHOOL: state => {
            return state.schoolAccount
        },
        IS_EXISTS_LOGIN : state => {
            return state.isLoginExists
        },
        LESSONS : (state) => (id) => {
            if (state.lessons[id] == null) return []
            return state.lessons[id]
        },
        TIME_NOW: state => {
            if (state.timeNow == null){
                return 0
            } else {
                //@ts-ignore
                return state.timeNow.toDate().getTime()
            }
        },
        PAY_STATUS: state => {
            return state.payStatus
        },
        ACTIVE_LESSON: state => {
            return state.activeLesson
        },

        ACTIVE_LESSONS: (state) => (id) => {
            if (state.activeLessons[id] == null) return false;
            return state.activeLessons[id]
        },

        TEACHERS: state => {
            return state.teachers
        },
        IS_FULLSCREEN_CAMERA: (state) => {
            return state.isFullscreenCamera
        },
        IS_FULLSCREEN_SCHEDULE: (state) => {
            return state.isFullscreenSchedule
        },
    },
    actions: {
        GET_PROFILE: async (context) => {
            if (context.state.profile == null) {
                await context.dispatch('UPDATE_LOCAL_PROFILE')
            }
        },
        GET_SCHOOL: async (context,{id}) => {
            if (context.state.schoolAccount == null) {
                await context.dispatch('UPDATE_LOCAL_SCHOOL', {id : id})
            }
        },
        UPDATE_LOCAL_PROFILE: async (context) => {
            const profile = await context.state.profileGateway.getAccount()
            if (profile != null) {
                context.commit('SET_PROFILE', profile)
                context.commit("SET_PAY_STATUS",(moment(profile.expiredAt.date).toDate().getTime() > new Date().getTime()))


                //@ts-ignore
                window.ChatraSetup = {
                    /* current user’s generated string */
                    clientId: profile.chatraToken
                };

                //eslint-disable-next-line no-undef
                Chatra('restart')

            } else {
                await router.push({name: "NewAuth"})
            }
        },
        UPDATE_LOCAL_SCHOOL: async ({state,commit},{id}) => {
            console.log(state.profile)
            console.log("id",id)
            const school = await state.profileGateway.getSchoolById(id)
            if (school != null){
                commit('SET_SCHOOL', school)
            }
        },
        UPDATE_PARENT_INFO: async({state,commit},{dataObject}) => {
            console.log(commit)
            const res = await state.profileGateway.updateMyUserDataNew(dataObject)
            return res
        },
        UPDATE_MY_PROFILE: async({state,commit},{dataObject}) => {
            console.log(commit)
            const res = await state.profileGateway.updateMyProfileData(dataObject)
            return res
        },

        UPDATE_LESSONS: async({state,commit} ,{schoolId,classId,crc32}) => {

            const lessons = await state.scheduleGateway.getScheduleBySchoolClassId(schoolId,classId,crc32)
            if (lessons !== false){
                commit("SET_LESSONS",{lessons : lessons,classId : classId})
                const activeLesson =  state.activeLessons[classId]

                if (lessons.length > 0) {
                    const lesson1 = lessons.filter((lesson) => {
                        return lesson.availableStatus === 1;
                    })[0]
                    if (lesson1 != null && (!activeLesson || activeLesson.id !== lesson1.id || activeLesson.end.date !== lesson1.end.date)){
                        commit("SET_ACTIVE_LESSONS",{activeLesson : lesson1,classId : classId})
                    } else if (lesson1 == null && activeLesson){
                        commit("SET_ACTIVE_LESSONS",{activeLesson : false,classId : classId})
                    }
                } else {
                    commit("SET_ACTIVE_LESSONS",{activeLesson : false,classId : classId})
                }
            }
        },

        CHECK_LOGIN_EXISTS: async({state,commit},{login}) => {
            const res = await state.profileGateway.checkLoginExist(login)
            commit("SET_LOGIN_EXISTS",res)
        },

        UPDATE_DEVICE_TOKEN: async({state,commit},{token}) => {
            console.log(state,commit)
            if (token != null && token.length > 0){
                await state.profileGateway.updateDeviceToken(token)
            }
        },


        EXIT_FROM_ACCOUNT: async ({state}) => {
            const res = await state.profileGateway.exitUser();

            //@ts-ignore
            window.ChatraSetup = {
                /* current user’s generated string */
                clientId: ""
            };




            //eslint-disable-next-line no-undef
            Chatra('restart')
            return res
        },


        RESET_STATE_TO_DEFAULT: ({state}) => {
            const state1 = {
                profile: null,
                profileGateway: new AccountGateway(),
                scheduleGateway: new ScheduleGateway(),
                teachers: [],
                token: null,
                schoolAccount : null,
                isLoginExists: false,
                lessons: state.lessons,
                timeNow: state.timeNow,
                secondsProgressLesson : state.secondsProgressLesson,
                payStatus : true,
                activeLesson : state.activeLesson,
                deviceToken: ""
            }


            Object.assign(state, state1)

        },


        UNION_ACCOUNTS: async ({state}, {data}) => {
            return await state.profileGateway.unionAccounts(data)
        },

        UPDATE_PUSH_STATUS : async ({state,commit},{data}) => {
            commit("SET_PUSH_ENABLED",data.isEnabled);
            return await state.profileGateway.setPushForToken(data)
        },

        GET_CODE: async ({state}) => {
            return await state.profileGateway.startSecurityCode();
        },


        VALIDATE_CODE: async ({state},{code}) => {
            return await state.profileGateway.validateSecurityCode(code);
        },


        SEND_SMS_CODE: async ({state}) => {
            return await state.profileGateway.sendUntrustedSmsCode();
        },

        //eslint-disable-next-line no-unused-vars
        changeFullScreen({state,commit},{isFullscreen,isSchedule,scheduleInfo}) {
            try {
                //eslint-disable-next-line no-undef
                androidAbility.changeFullScreen(isFullscreen)
            } catch (e) {
                //eslint-disable-next-line no-undef
                try{
                    window.webkit.messageHandlers.changeFullScreenHandler.postMessage(isFullscreen)
                } catch (e){
                    console.log(e)
                }
            }

            if (isSchedule){
                commit("SET_FULLSCREEN_SCHEDULE",scheduleInfo)
            } else {
                commit("SET_FULLSCREEN_CAMERA",!!isFullscreen)
            }
            return !!isFullscreen

        },
    },
})
