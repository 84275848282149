import {BaseHttpGateway} from "@/gateway/Base/BaseHttpGateway";

export class ScheduleGateway extends BaseHttpGateway{

    async getScheduleBySchoolClassId(educationObjectId,schoolClassId,crc32){
        const res = await this.httpClient.get(`education/${educationObjectId}/schoolClass/${schoolClassId}/lessons/${crc32}`);

        return res.data;
    }
}
