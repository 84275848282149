import axios from 'axios'
import {vueApp} from "@/main";

export class HttpClient {
    // static BASE_URL = "http://192.168.0.188:8085/";
    // static BASE_URL = "https://api.esova.ru/";
    static BASE_URL = "https://test-api.esova.ru/";
    static API_VERSION = "";
    static httpClient = null;
    static isUpdate = false;

    static getHttpClient(){
        if (HttpClient.httpClient == null){
            HttpClient.httpClient = this.make();
        }
        return HttpClient.httpClient;
    }

    static make(){
        let httpClient = axios.create({
            baseURL: `${this.BASE_URL}/${this.API_VERSION}/`
        })

        httpClient.interceptors.request.use(this.requestInterceptor,function (error) {
            return Promise.reject(error)
        });

        httpClient.interceptors.response.use(this.responseInterceptor,this.authenticatorInterceptor);

        return httpClient;
    }

    static requestInterceptor(config){
        if (config.headers.common["Authorization"] == null){
            config.headers.common['Authorization'] = `Bearer ${(document.location.href.indexOf("admin") >= 0) ? localStorage.getItem('adminAccessToken') : localStorage.getItem('accessToken')}`;
        }

        if (config.headers["Content-Type"] == null){
            config.headers.common['Content-Type'] = "application/json"
        }
        return config;
    }

    static authenticatorInterceptor(error) {

        if (error.response == null) throw error;
        if (error.response.status === 401){
            const refreshToken = (document.location.href.indexOf("admin") >= 0) ? localStorage.getItem("adminRefreshToken") : localStorage.getItem("refreshToken");
            if (!HttpClient.isUpdate){
                HttpClient.isUpdate = true;

                return HttpClient.getHttpClient().put(`auth/${refreshToken}`,null).then(async (response) =>{
                    if (response == null){
                        // document.location.href = 'auth'
                    }


                    // if (vueApp.$router.currentRoute.name !== 'AuthView' && response.status === 400) {
                    //     const deviceToken = localStorage.getItem("deviceToken")
                    //     if (deviceToken != null && deviceToken.length > 0) {
                    //         await HttpClient.getHttpClient().delete(`token/${deviceToken}`)
                    //     }
                    //     if (document.location.href.indexOf("admin") >= 0) {
                    //         await vueApp.$router.push({name : 'AuthView'})
                    //     } else {
                    //         await vueApp.$router.push({name : 'AuthView'})
                    //         return;
                    //     }
                    // }

                    if (vueApp.$router.currentRoute.name !== 'AuthView' && response.status === 400) {
                        const deviceToken = localStorage.getItem("deviceToken")
                        if (deviceToken != null && deviceToken.length > 0) {
                            await HttpClient.getHttpClient().delete(`token/${deviceToken}`)
                        }
                        if (document.location.href.indexOf("admin") >= 0) {
                            document.location.href = '/admin/auth'
                        } else {
                            document.location.href = '/auth'
                        }
                    }

                    // if (response.status === 400){
                    //
                    //     const deviceToken = localStorage.getItem("deviceToken")
                    //     if (deviceToken != null && deviceToken.length > 0){
                    //         await HttpClient.getHttpClient().delete(`token/${deviceToken}`)
                    //     }
                    //     if (document.location.href.indexOf("admin") >= 0){
                    //         document.location.href = '/admin/auth'
                    //     } else {
                    //         document.location.href = '/auth'
                    //     }
                    // }

                    const token = response.data;

                    if (document.location.href.indexOf("admin") >= 0){
                        localStorage.setItem('adminAccessToken', token.accessToken);
                        localStorage.setItem('adminRefreshToken', token.refreshToken);
                    } else {
                        localStorage.setItem('accessToken', token.accessToken);
                        localStorage.setItem('refreshToken', token.refreshToken);
                    }


                    error.config.headers['Authorization'] = `Bearer ${token.accessToken}`;
                    HttpClient.isUpdate = false;
                    return HttpClient.getHttpClient().request(error.config);
                })
            }
        } else if (error.response.status === 403){
            // vueApp.$router?.push({name : 'SecurityView'})
            document.location.href = '/security'
        } else {
            return error.response;
        }
    }

    static responseInterceptor(response){
        return response;
    }





}
