import {BaseHttpGateway} from "@/gateway/Base/BaseHttpGateway";
import {WrongLoginOrPasswordException} from "@/exception/WrongLoginOrPasswordException";
import {NeedMoreInformationException} from "@/exception/NeedMoreInformationException";

export class AccountGateway extends BaseHttpGateway{
    async auth(authRequest){
        const res = await this.httpClient.post("/auth",authRequest);
        if (res.status != 200){
            if (res.status === 400) {
                throw new WrongLoginOrPasswordException();
            } else {
                throw new Error("что то произошло")
            }
        }
        return res.data
    }

    async exitUser(){
        const res = await this.httpClient.delete("/auth");
        return res.status === 200;
    }

    async getAccount(){
        const res = await this.httpClient.get("/user");

        if (res.status != 200){
            if (res.status == 400){
                throw new NeedMoreInformationException(res.data.schoolClass,res.data);
            }
        }


        return res.data;
    }

    async updateMyUserData(user){
        const res = await this.httpClient.put("/user",user);
        return (res.status === 204);
    }

    async updateMyProfileData(user){
        const res = await this.httpClient.put("/user/profile",user);
        return (res.status === 204);
    }

    async updateMyUserDataNew(dataObject){
        const res = await this.httpClient.put("/user",dataObject);
        return (res.status === 204);
    }

    async checkLoginExist(login){
        const res = await this.httpClient.get(`user/${login}`)

        return (res.status !== 204)
    }

    async getSchoolById(id){
        const res = await this.httpClient.get(`/account/${id}`);
        return res.data
    }


    async updateDeviceToken(deviceToken){
        const res = await this.httpClient.put(`/token/${deviceToken}`)
        return res.data
    }

    async unionAccounts(data){
        const res = await this.httpClient.post(`user/union`,data)

        return res.status
    }

    async setPushForToken(data){
        const res = await this.httpClient.post(`user/push`,data)

        return res.status;
    }

    async startSecurityCode(){
        const res = await this.httpClient.post(`trust`)

        if (res.status === 200){
            return res.data;
        } else {
            return false
        }
    }

    async validateSecurityCode(code){
        const res = await this.httpClient.put(`trust/${code}`);

        return res.status === 200
    }

    async sendUntrustedSmsCode(){
        const res = await this.httpClient.post(`trust/sms`);

        if (res.status === 200){
            return res.data;
        } else {
            return false
        }
    }


}
