import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
    {
        path: '/auth',
        name: 'AuthView',
        component : () => import("../view/auth/AuthView")
    },
    {
        path: '/',
        name: 'MainView',
        component: () => import("../view/main/MainView"),
        children:[
            {
                path: 'user',
                name: 'ParentView',
                component: () => import("../view/parent/ParentView")
            },
            {
                path: 'teacher',
                name: 'TeacherView',
                component: () => import("../view/teacher/TeacherView")
            },
        ]
    },
    {
        path: '/settings',
        name: 'SettingsMainView',
        component: () => import('../view/settings/SettingsMainView.vue'),
        children: [
            {
                path: 'settings',
                name: 'SettingsMainListView',
                component: () => import('../view/settings/SettingsMainListView.vue'),
                meta: {
                    title: '',
                }
            },
            {
                path: 'classes',
                name: 'SettingsClassesView',
                component: () => import('../view/settings/SettingsClassesView.vue'),
                meta: {
                    title: 'Родительский кабинет',
                },
                children: [
                    {
                        path: 'add',
                        name: 'SettingsClassesAddView',
                        component: () => import('../view/settings/SettingsClassesAddView.vue'),
                        meta: {
                            title: 'Родительский кабинет',
                        },
                    }
                ]
            },
            {
                path: 'profile',
                name: 'SettingsProfileView',
                component: () => import('../view/settings/SettingsProfileView.vue'),
                meta: {
                    title: 'Настройки профиля',
                }
            },
            {
                path: 'faq',
                name: 'SettingsFaqView',
                component: () => import('../view/settings/SettingsFaqView.vue'),
                meta: {
                    title: 'FAQ',
                }
            },
        ]
    },
    {
        path: '/interview',
        name: 'InterviewView',
        component: () => import("../view/parent/InterviewView")
    },
    {
        path: '/security',
        name: 'SecurityView',
        component: () => import("../view/Code/CodeView.vue"),
        meta: {
            title: 'Система безопасности',
        }
    },
    {
        path: '/congratulation',
        name: 'CongratulationView',
        component: () => import("../view/parent/CongratulationView.vue"),
        meta: {
            title: 'Поздравляем'
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
